import React from "react";
import styles from "./Hamburger.module.css";

const Hamburger = ({ open, setOpen }) => {
  const burgerDivClass = open
    ? `${styles.hamburgerDiv} ${styles.openHamburgerDiv}`
    : styles.hamburgerDiv;

  const firstChildClass = open ? `${styles.rotate45}` : "";
  const secondChildClass = open ? `${styles.translate20px}` : "";
  const thirdChildClass = open ? `${styles.rotateMinus45}` : "";

  return (
    <button className={styles.hamburger} onClick={() => setOpen(!open)}>
      <div className={`${burgerDivClass} ${firstChildClass}`} />
      <div className={`${burgerDivClass} ${secondChildClass}`} />
      <div className={`${burgerDivClass} ${thirdChildClass}`} />
    </button>
  );
};

export default Hamburger;
