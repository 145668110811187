import React from "react";
import styles from "./InvitationForm.module.scss";

function InvitationForm({
  data,
  password,
  passwordStrength,
  handlePasswordChange,
  handleSetPassword,
  loading,
  updateError,
  isLoading,
  errorMessage,
  isActive,
  passwordSet,
  invitationExpired,
  countdown,
}) {
  const renderFormContent = () => {
    if (isLoading) {
      return <h2 className={styles.infoMonit}>Loading...</h2>;
    }

    if (errorMessage || !isActive) {
      return (
        <h2 className={styles.infoMonit}>
          {errorMessage ||
            "Dieser Einladungslink ist entweder verwendet oder abgelaufen."}
        </h2>
      );
    }

    if (passwordSet) {
      return (
        <h2 className={styles.infoMonit}>
          <div>Das Passwort wurde erfolgreich festgelegt.</div>
          <div>
            Sie werden in {countdown} Sekunden zur Anmeldeseite weitergeleitet.
          </div>
        </h2>
      );
    }

    if (invitationExpired) {
      return (
        <h2 className={styles.infoMonit}>
          Dieser Einladungslink ist entweder verwendet oder abgelaufen.
        </h2>
      );
    }

    return (
      <>
        <h2 className={styles.formTitle}>Willkommen</h2>
        <div className={styles["input-wrapper"]}>
          <label htmlFor='new-password'>E-Mail:</label>
          <input
            id='user_email'
            type='email'
            value={data.email}
            className={styles.input}
            disabled
          />
        </div>
        <div className={styles["input-wrapper"]}>
          <label htmlFor='new-password'>Neues Passwort:</label>
          <input
            id='new-password'
            type='password'
            value={password}
            onChange={handlePasswordChange}
            className={styles.input}
          />
        </div>
        {passwordStrength === false && (
          <p>
            Das Passwort sollte mindestens einen Kleinbuchstaben, einen
            Großbuchstaben, eine Ziffer und mindestens 8 Zeichen enthalten.
          </p>
        )}
        {updateError && <div className={styles.error}>{updateError}</div>}

        {loading && <p>Neues Passwort festlegen...</p>}
        <button
          type='submit'
          className={styles["login-modal-button"]}
          disabled={loading}
        >
          Passwort festlegen
        </button>
      </>
    );
  };
  return (
    <div className={styles["form-wrapper"]}>
      <form className={styles.form} onSubmit={handleSetPassword}>
        {renderFormContent()}
      </form>
    </div>
  );
}

export default InvitationForm;
