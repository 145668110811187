import React, { useContext } from "react";
import LoginForm from "../LoginForm/LoginForm";
import LoginContext from "../../contexts/LoginContext";
import styles from "./LoginModal.module.css";
import useAuth from "../../hooks/useAuth";

const LoginModal = () => {
  const { isLoginModalOpen, toggleLoginModal, logIn } =
    useContext(LoginContext);
  const auth = useAuth();

  const handleLogin = async (email, password) => {
    try {
      const response = await auth.authenticate(email, password);
      if (response.access_token) {
        logIn(response.access_token);
        toggleLoginModal();
      }
    } catch (error) {
      console.warn(error);
    }
  };
  const handleModalClose = () => {
    auth.setError("");
    toggleLoginModal();
  };
  if (!isLoginModalOpen) return null;

  return (
    <div className={styles.modal}>
      <div className={styles["modal-content"]}>
        <h2>Login</h2>
        <LoginForm onLogin={handleLogin} error={auth.error} />
        <button
          className={styles["close-modal-button"]}
          onClick={handleModalClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default LoginModal;
