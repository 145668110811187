import React from "react";
import style from "./LoginPageLayout.module.scss";
import logo from "../../images/gigahub-logo.png";
import gigagreenintrovideo from "../../videos/GigaGreenIntroVideo.mp4";

const LoginPageLayout = ({ children }) => {
  return (
    <div className={style["page-layout"]}>
      <div className={style["content-container"]}>
        <div className={style.logo}>
          <img src={logo} alt='Logo' />
        </div>
        {children}
      </div>
      <div className={style["video-container"]}>
        <video
          className={style["background-video"]}
          autoPlay
          loop
          muted
          onCanPlayThrough={(e) => e.target.play()}
        >
          <source src={gigagreenintrovideo} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default LoginPageLayout;
