import React, { useState } from "react";
import styles from "./LoginForm.module.scss";

const LoginForm = ({ onLogin, error, clearError }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
    if (error) {
      clearError();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onLogin(email, password);
  };

  return (
    <div className={styles.loginFormWrapper}>
      <h2 className={styles.formTitle}>Anmelden</h2>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles["input-wrapper"]}>
          <label htmlFor='email'>E-mail:</label>
          <input
            type='email'
            id='email'
            name='email'
            value={email}
            onChange={handleInputChange(setEmail)}
            required
            className={styles.input}
          />
        </div>
        <div className={styles["input-wrapper"]}>
          <label htmlFor='password'>Passwort:</label>
          <input
            type='password'
            id='password'
            name='password'
            value={password}
            onChange={handleInputChange(setPassword)}
            required
            className={styles.input}
          />
        </div>
        {error && (
          <p className={styles.error} data-testid='login-error'>
            {error}
          </p>
        )}
        <button
          type='submit'
          className={styles["login-modal-button"]}
          data-testid='login-button'
        >
          Anmelden
        </button>
      </form>
    </div>
  );
};

export default LoginForm;
