import React, { useState } from "react";

function Tooltip({ children, content }) {
  const [visible, setVisible] = useState(false);

  return (
    <div
      style={{ position: "relative" }}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {children}
      {visible && (
        <span
          style={{
            position: "absolute",
            backgroundColor: "#043528",
            color: "white",
            padding: "5px",
            borderRadius: "3px",
            top: "-100%",
            left: "50%",
            transform: "translate(-50%, 50%)",
            whiteSpace: "nowrap",
          }}
        >
          {content}
        </span>
      )}
    </div>
  );
}

export default Tooltip;
