import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import styles from "./FileDropzone.module.css";
import uploadImg from "../../images/uploadIcon.svg";
import Loader from "../Loader/Loader";

export const FileDropzone = ({ onDrop, uploadProgress, onUploadComplete }) => {
  const [isUploading, setIsUploading] = useState(false);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: async (acceptedFiles) => {
      setIsUploading(true);
      try {
        await onDrop(acceptedFiles);
        onUploadComplete();
      } catch (error) {
        console.error(error);
      } finally {
        setIsUploading(false);
      }
    },
    allowMultiple: true,
  });

  return (
    <>
      <div
        {...getRootProps()}
        className={styles.dropzone}
        style={{
          border: "2px dashed #ccc",
          padding: "75px",
          textAlign: "center",
          backgroundColor: isDragActive ? "#ccc" : "white",
          color: "#ccc",
        }}
      >
        {isUploading ? (
          <>
            <Loader />
            <p>Upload file in progress</p>
            <div className={styles.progressBarContainer}>
              <div
                className={styles.progressBar}
                style={{ width: `${uploadProgress}%` }}
              ></div>
            </div>
          </>
        ) : (
          <>
            <input {...getInputProps()} />
            <img src={uploadImg} alt='' />
            <p className={styles["dropzone-info"]}>
              Datei hierher ziehen und ablegen oder{" "}
              <u>
                <strong>Datei auswählen</strong>
              </u>
            </p>
          </>
        )}
      </div>
    </>
  );
};

export default FileDropzone;
