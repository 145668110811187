import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isPasswordStrong } from "../utils/passwordUtils";
import InvitationForm from "../components/InvitationForm/InvitationForm";
import { useInvitationData } from "../hooks/useInvitationsData";
import { updateInvitation } from "../api/invitationsApi";
import LoginPageLayout from "../components/LoginPageLayout/LoginPageLayout";
import InfoBox from "../components/Infobox/Infobox";

function InvitationPage() {
  const { uuid } = useParams();
  const { data, isActive, isLoading, errorMessage } = useInvitationData(uuid);
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(null);
  const [passwordSet, setPasswordSet] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateError, setUpdateError] = useState(null);
  const [invitationExpired, setInvitationExpired] = useState(false);
  const [countdown, setCountdown] = useState(5);

  const handleSetPassword = async (e) => {
    e.preventDefault();

    if (!uuid) {
      console.warn("Missing uuid.");
      return;
    }

    if (isPasswordStrong(password)) {
      setLoading(true);
      try {
        await updateInvitation(uuid, password);
        setPasswordSet(true);
        const intervalId = setInterval(() => {
          setCountdown((prev) => {
            if (prev <= 1) {
              clearInterval(intervalId);
              navigate("/");
            }
            return prev - 1;
          });
        }, 1000);
      } catch (error) {
        console.error("Error setting password:", error.message);
        setUpdateError(
          error.message || "An error occurred while updating the invitation."
        );
        if (
          error.message ===
          "Dieser Einladungslink ist entweder verwendet oder abgelaufen"
        ) {
          setInvitationExpired(true);
        }
      } finally {
        setLoading(false);
      }
    } else {
      console.warn("Password not strong enough.");
      setPasswordStrength(false);
    }
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setPasswordStrength(null);
  };
  return (
    <LoginPageLayout>
      <InvitationForm
        data={data}
        password={password}
        passwordStrength={passwordStrength}
        handlePasswordChange={handlePasswordChange}
        handleSetPassword={handleSetPassword}
        loading={loading}
        updateError={updateError}
        isLoading={isLoading}
        errorMessage={errorMessage}
        isActive={isActive}
        passwordSet={passwordSet}
        invitationExpired={invitationExpired}
        countdown={countdown}
      />
      <InfoBox />
    </LoginPageLayout>
  );
}

export default InvitationPage;
