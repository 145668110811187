import { useState, useEffect } from "react";
import axios from "axios";

const useFetchUser = (accessToken, isLoggedIn) => {
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!accessToken || !isLoggedIn) return;

      setIsLoading(true);
      try {
        const response = await axios.get("/api/v1/users/me", {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        setUserData(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [accessToken, isLoggedIn]);

  return { userData, isLoading, error };
};

export default useFetchUser;
