import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import ItemsList from "../components/ItemsList/ItemsList";
import useFetchClients from "../hooks/useFetchClients";

const AccountsPage = () => {
  const { clients, loading, error } = useFetchClients();
  const [searchTerm, setSearchTerm] = useState("");

  const matchesSearchTerm = (client) => {
    const fieldsToSearch = [client.name, client._id];
    const cleanedSearchTerm = searchTerm.replace(/\s+/g, "").toLowerCase();
    return fieldsToSearch.some((field) =>
      field?.replace(/\s+/g, "").toLowerCase().includes(cleanedSearchTerm)
    );
  };

  const filteredClients = clients.filter(matchesSearchTerm);

  return (
    <>
      <ItemsList
        listTitle={"Accounts"}
        listSubtitle={
          "Willkommen zu Ihrer Kontenliste. Hier können Sie das Konto auswählen, um die dazugehörigen Projekte zu sehen."
        }
        items={filteredClients}
        loading={loading}
        error={error}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        noItemsMessage={"Keine Konten gefunden"}
        noItemsFoundMessage={"Keine Konten gefunden"}
        searchPlaceholder={"Client, Client-ID suchen..."}
      />
      <Outlet />
    </>
  );
};

export default AccountsPage;
