import { createContext, useContext, useState, useEffect } from "react";
import { getCookie, setCookie } from "../utils/cookies";
import LoginContext from "./LoginContext";

export const PermissionsContext = createContext();

export const usePermissions = () => {
  return useContext(PermissionsContext);
};

export const PermissionsProvider = ({ children }) => {
  const [permissions, setPermissions] = useState({
    role: null,
    can_change_file_status: false,
  });
  const { logOut } = useContext(LoginContext);

  useEffect(() => {
    const storedPermissions = getCookie("permissions");
    if (storedPermissions) {
      setPermissions(JSON.parse(storedPermissions));
    } else {
      logOut();
    }
  }, [logOut]);

  const updatePermissions = (newPermissions) => {
    setPermissions(newPermissions);
    setCookie("permissions", JSON.stringify(newPermissions), {
      days: 7,
      path: "/",
      secure: true,
      httpOnly: false,
      sameSite: "strict",
    });
  };

  return (
    <PermissionsContext.Provider value={{ permissions, updatePermissions }}>
      {children}
    </PermissionsContext.Provider>
  );
};
