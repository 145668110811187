import React, { useContext, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { usePermissions } from "../../contexts/PermissionsContext";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import styles from "./Navbar.module.css";
import Hamburger from "../Hamburger/Hamburger";
import FileSearch from "../FileSearch/FileSearch";
import { ClientIdContext } from "../../contexts/ClientIdContext";

const Navbar = ({
  currentProject,
  setSelectedSubcategory,
  handleSearchResults,
  files,
  searchResults,
}) => {
  const { projectId } = useParams();
  const { permissions } = usePermissions();
  const [isOpen, setIsOpen] = useState(true);
  const { clientId } = useContext(ClientIdContext);
  const getClassName = (isActive) =>
    `${styles["nav-item"]} ${isActive ? styles.active : ""}`;
  const toggleMenu = () => setIsOpen(!isOpen);

  const links = [
    {
      roles: ["admin", "manager", "customer"],
      to: `/projectlist/project/${projectId}/kundendokumente`,
      title: "Kundendokumente",
    },
    {
      roles: ["admin", "manager"],
      to: `/projectlist/project/${projectId}/giga-green`,
      title: "Giga.Green",
    },
    {
      roles: ["admin", "manager", "customer"],
      to: `/projectlist/project/${projectId}/vertrage-und-angebote`,
      title: "Verträge und Angebote",
    },
    {
      roles: [
        "admin",
        "manager",
        "customer",
        "supplier",
        "solar_engineer",
        "structural_engineer",
        "electrician",
      ],
      to: `/projectlist/project/${projectId}/vorlagen-informationen`,
      title: "Informationen und Vorlagen",
      condition: !currentProject.common,
    },
    {
      roles: [
        "admin",
        "manager",
        "supplier",
        "solar_engineer",
        "structural_engineer",
        "electrician",
      ],
      to: `/projectlist/project/${projectId}/dienstleister`,
      title: "Dienstleister",
    },
  ];

  const shouldShowInfoAndTemplatesLink = (title) =>
    (title === "Informationen und Vorlagen" && currentProject.common) ||
    (!currentProject.common && title !== "Informationen und Vorlagen");

  const visibleLinks = links.filter(
    (link) =>
      link.roles.includes(permissions.role) &&
      shouldShowInfoAndTemplatesLink(link.title)
  );
  return (
    <div className='stickyNavbar' data-testid='navbar'>
      <div className={styles["breadcrumbs-wrapper"]}>
        {![
          "supplier",
          "solar_engineer",
          "structural_engineer",
          "electrician",
        ].includes(permissions.role) &&
          !currentProject.common && (
            <Breadcrumbs currentProject={currentProject} />
          )}
        {(currentProject.common ||
          [
            "supplier",
            "solar_engineer",
            "structural_engineer",
            "electrician",
          ].includes(permissions.role)) && (
          <NavLink to={`/projectlist`} className={styles.link}>
            Projekte
          </NavLink>
        )}
        <FileSearch
          files={files}
          onSearch={handleSearchResults}
          clientId={clientId}
          projectId={projectId}
          onSubcategorySelect={setSelectedSubcategory}
          permissions={permissions}
        />
        <div>
          {searchResults.map((file) => (
            <div key={file._id}>
              <h3>{file.name}</h3>
              <p>Category: {file.category}</p>
              <p>Subcategory: {file.subcategory}</p>
            </div>
          ))}
        </div>
      </div>
      <div className='container'>
        <div className={styles.hamburger} onClick={toggleMenu}>
          <Hamburger isOpen={isOpen} setOpen={setIsOpen} />
        </div>
        <nav className={`${styles.navbar} ${isOpen ? "" : styles.hide}`}>
          {visibleLinks.map((link, index) => (
            <React.Fragment key={link.title}>
              <NavLink
                to={link.to}
                className={({ isActive }) => getClassName(isActive)}
                data-testid={`navlink-${index}`}
              >
                {link.title}
              </NavLink>
              {link !== visibleLinks[visibleLinks.length - 1] && (
                <div className={styles.line}></div>
              )}
            </React.Fragment>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
