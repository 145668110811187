import React, { useState } from "react";
import style from "./List.module.css";
import Status from "../Status/Status";
import Modal from "../Modal/Modal";
import { useContext } from "react";
import LoginContext from "../../contexts/LoginContext";
import StatusChangeModal from "../StatusChangeModal/StatusChangeModal";
import { usePermissions } from "../../contexts/PermissionsContext";
import { useS3Client } from "../../hooks/useS3Client";

const List = ({
  files,
  onUpdateFileComment,
  onDownloadFile,
  // clientId,
  // projectId,
  onUpdateFileStatus,
  templateProject,
  // currentProject,
  deleteFile,
  subcategory,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [selectedComment, setSelectedComment] = useState("");
  const { isLoggedIn } = useContext(LoginContext);
  const [showStatusChangeModal, setShowStatusChangeModal] = useState(false);
  const handleCommentButtonClick = (fileId, fileComment) => {
    setSelectedFileId(fileId);
    setSelectedComment(fileComment);
    setShowModal(true);
  };
  const { getPreviewUrl } = useS3Client();
  const { permissions } = usePermissions();
  // const finalClientId =
  //   currentProject &&
  //   currentProject.common &&
  //   clientId !== currentProject.client._id
  //     ? currentProject.client._id
  //     : clientId;

  const handleFilePreviewClick = async (filePath) => {
    const previewUrl = await getPreviewUrl(filePath);
    window.open(previewUrl, "_blank");
  };
  const handleStatusChangeButtonClick = (fileId) => {
    setSelectedFileId(fileId);
    setShowStatusChangeModal(true);
  };

  const handleDeleteButtonClick = (filePath, fileName) => {
    deleteFile(filePath, fileName);
  };

  const getFileFormat = (mimeType) => {
    const mimeTypeToFormat = {
      "image/jpeg": "JPEG Image",
      "image/png": "PNG Image",
      "image/gif": "GIF Image",
      "application/pdf": "PDF Document",
      "application/msword": "Word Document",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        "Word Document",
      "application/vnd.ms-excel": "Excel Spreadsheet",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        "Excel Spreadsheet",
      "application/vnd.ms-powerpoint": "PowerPoint Presentation",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        "PowerPoint Presentation",
      "text/plain": "Text File",
    };

    return mimeTypeToFormat[mimeType.toLowerCase()] || "Unknown Format";
  };
  return (
    <>
      <StatusChangeModal
        show={showStatusChangeModal}
        onClose={() => setShowStatusChangeModal(false)}
        onSave={(newStatus) => {
          onUpdateFileStatus(selectedFileId, newStatus);
          setShowStatusChangeModal(false);
        }}
        currentStatus={
          files.find((file) => file._id === selectedFileId)?.status || "New"
        }
      />
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        onSave={(newComment) => {
          onUpdateFileComment(
            selectedFileId,
            newComment.trim() === "" ? null : newComment
          );
          setSelectedComment("");
          setShowModal(false);
        }}
        existingComment={selectedComment}
      />
      <ul className={style["list-container"]} data-testid={subcategory}>
        <li className={style["list-header"]}>
          <div className={style["left-side"]}>
            <span>Dateiname</span>
          </div>
          <div className={style["right-side"]}>
            <span>Kommentar</span>
            <span>Status</span>
          </div>
        </li>
        {files.map((file, index) => (
          <li key={index} className={style["list-item"]}>
            <div className={style["left-side"]}>
              <div className={style["file-title"]}>
                <button
                  data-testid={`preview-button`}
                  onClick={() => handleFilePreviewClick(file.path)}
                >
                  {file.name}
                </button>
                <div className={style["file-subinfo"]}>
                  Dateityp: {getFileFormat(file.extension)}
                </div>
              </div>
            </div>
            <div className={style["right-side"]}>
              {isLoggedIn && (
                <button
                  data-testid={
                    file.comment && file.comment !== "null"
                      ? "comment-button-2"
                      : "comment-button-1"
                  }
                  disabled={!templateProject}
                  className={`${style["comment-button"]} ${
                    !templateProject ? style["template"] : ""
                  }`}
                  onClick={() =>
                    handleCommentButtonClick(file._id, file.comment)
                  }
                >
                  {file.comment && file.comment !== "null"
                    ? "Kommentar bearbeiten"
                    : "Kommentar hinzufügen"}
                </button>
              )}
              {isLoggedIn && (
                <button
                  data-testid={`download-button`}
                  className={style["download-button"]}
                  onClick={() => onDownloadFile(file.path, file.name)}
                >
                  Herunterladen
                </button>
              )}
              {(permissions.role === "admin" ||
                permissions.role === "manager") && (
                <button
                  className={style["download-button"]}
                  data-testid={"delete-button"}
                  onClick={() => handleDeleteButtonClick(file.path, file._id)}
                >
                  Datei löschen
                </button>
              )}
              {permissions.can_change_file_status && (
                <Status
                  status={file.status}
                  onClick={() => handleStatusChangeButtonClick(file._id)}
                />
              )}
              {!permissions.can_change_file_status && (
                <Status status={file.status} />
              )}
            </div>
            {file.comment && file.comment !== "null" && (
              <div
                className={style["comment-section"]}
                data-testid={`comment-field`}
              >
                {file.comment}
              </div>
            )}
          </li>
        ))}
      </ul>
    </>
  );
};

export default List;
