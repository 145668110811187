import React, { useContext, useRef, useState, useEffect } from "react";
import LoginContext from "../../contexts/LoginContext";
import styles from "./Header.module.css";
import user_icon from "../../images/avatar.png";
import ModalComponent from "../ModalComponent/ModalComponent";
import PasswordChangeForm from "../PasswordChangeForm/PasswordChangeForm";
import { useNavigate } from "react-router-dom";
import { ClientIdContext } from "../../contexts/ClientIdContext";

const Header = () => {
  const { isLoggedIn, logOut } = useContext(LoginContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const { setClientId } = useContext(ClientIdContext);

  const navigate = useNavigate();
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleLogout = () => {
    if (window.confirm("Sind Sie sicher, dass Sie sich abmelden möchten?")) {
      logOut();
      setShowDropdown(false);
      setClientId(null);
      navigate("/");
    }
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const wrapperRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);
  return (
    <>
      <ModalComponent
        show={isModalOpen}
        onClose={handleCloseModal}
        title='Passwort ändern'
      >
        <PasswordChangeForm />
      </ModalComponent>
      <div className='stickyLogo'>
        <div className={styles["container-header"]}>
          <div className={styles["right-header"]}>
            {isLoggedIn && (
              <div className={styles["buttons-wrapper"]} ref={wrapperRef}>
                <button data-testid={"user_icon"} onClick={toggleDropdown}>
                  <img src={user_icon} alt='user icon' />
                  Profil
                </button>
                {showDropdown && (
                  <div
                    className={styles["dropdown-menu"]}
                    data-testid='dropdown'
                  >
                    <button onClick={handleOpenModal}>Passwort ändern</button>
                    <button onClick={handleLogout}>Abmelden</button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
