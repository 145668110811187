import axios from "axios";
import { useEffect, useState } from "react";
import { getAccessToken } from "../utils/auth";

const useFetchProjects = (linked = false) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const accessToken = getAccessToken();
  useEffect(() => {
    const fetchProjects = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `/api/v1/storage/projects?linked=${linked}`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        );
        setProjects(response.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchProjects();
  }, [linked, accessToken]);

  return { projects, loading, error };
};

export default useFetchProjects;
