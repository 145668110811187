import React, { useEffect, useState } from "react";
import { usePermissions } from "../contexts/PermissionsContext";
import useFetchProjects from "../hooks/useFetchProjects";
import ItemsList from "../components/ItemsList/ItemsList";
import { Outlet, useSearchParams } from "react-router-dom";
import supplierRoles from "../constants/supplierRoles";

const ClientPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isListView, setIsListView] = useState(
    () => JSON.parse(localStorage.getItem("isListView")) ?? true
  );
  const { permissions } = usePermissions();
  const [searchParams] = useSearchParams();
  const searchParam = searchParams.get("searchParam");

  const role = permissions.role;
  const isSupplierRole = supplierRoles.includes(role);
  const { projects = [], loading, error } = useFetchProjects(isSupplierRole);
  const commonProject = projects.find((project) => project.common);

  const noProjectsFoundMessage = (
    <>
      Wir bedauern Ihnen mitteilen zu müssen, dass keine Projekte mit Ihrem
      Konto verknüpft sind. Bitte wenden Sie sich an Ihren Account-Betreuer oder
      kontaktieren Sie unsere Service-E-Mail unter{" "}
      <a href='mailto:service@giga.green'>service@giga.green</a>, um weitere
      Unterstützung zu erhalten.
    </>
  );

  const noItemsFoundMessage = <>Keine Ergebnisse gefunden.</>;

  const matchesSearchTerm = (project) => {
    const fieldsToSearch = [
      project.name,
      project.client?.name,
      project.client?.monday_id,
      project.client?._id,
      project._id,
      project.monday_id,
    ];
    const cleanedSearchTerm = searchTerm.replace(/\s+/g, "").toLowerCase();
    return fieldsToSearch.some((field) =>
      field?.replace(/\s+/g, "").toLowerCase().includes(cleanedSearchTerm)
    );
  };
  const filteredProjects = projects
    .filter((project) => !project.common)
    .filter(matchesSearchTerm);
  useEffect(() => {
    if (searchParam) {
      setSearchTerm(searchParam || "");
    }
  }, [searchParam]);
  return (
    <>
      <ItemsList
        listTitle={"Projekte"}
        listSubtitle={
          "Willkommen zu Ihrer Projekte. Hier können Sie das Projekt auswählen, um seine zugehörigen Dokumente hochzuladen."
        }
        items={filteredProjects}
        loading={loading}
        error={error}
        commonProject={commonProject}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        noItemsMessage={noProjectsFoundMessage}
        noItemsFoundMessage={noItemsFoundMessage}
        toggleView={() => setIsListView(!isListView)}
        isListView={isListView}
        searchPlaceholder={"Projekt, Kunde oder Projekt-ID suchen..."}
      />
      <Outlet />
    </>
  );
};

export default ClientPage;
