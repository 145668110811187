import { useContext, useState, useEffect } from "react";
import axios from "axios";
import {
  saveAccessToken,
  getAccessToken,
  getPermissions,
  savePermissions,
} from "../utils/auth";
import { PermissionsContext } from "../contexts/PermissionsContext";

const useAuth = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { updatePermissions } = useContext(PermissionsContext);

  useEffect(() => {
    const token = getAccessToken();
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const authenticate = async (email, password) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        "/api/v1/login/access-token",
        {
          grant_type: "",
          username: email,
          password: password,
          scope: "",
          client_id: "",
          client_secret: "",
        },
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );

      const { access_token } = response.data;
      saveAccessToken(access_token);
      setIsAuthenticated(true);
      let permissions = getPermissions();

      if (!permissions) {
        const permissionsResponse = await axios.get("/api/v1/permissions/", {
          headers: { Authorization: `Bearer ${access_token}` },
        });
        permissions = permissionsResponse.data;
        updatePermissions(permissions);

        savePermissions(permissions);
      }

      setLoading(false);
      return response.data;
    } catch (err) {
      setLoading(false);
      console.warn(err);
      if (err.response) {
        if (err.response.status === 400) {
          setError("Zugangsdaten nicht korrekt"); // "Access data not correct"
        } else if (err.response.status === 401) {
          setError("Unautorisiert: Falsche Anmeldeinformationen"); // "Unauthorized: Incorrect login credentials"
        } else if (err.response.status === 403) {
          setError("Verboten: Sie haben keinen Zugang zu dieser Ressource"); // "Forbidden: You do not have access to this resource"
        } else if (err.response.status === 500) {
          setError(
            "Interner Serverfehler: Bitte versuchen Sie es später noch einmal"
          ); // "Internal Server Error: Please try again later"
        } else {
          setError(err.response.data.detail || "Etwas ist schiefgelaufen"); // "Something went wrong"
        }
      } else if (err.request) {
        setError(
          "Netzwerkfehler: Bitte überprüfen Sie Ihre Internetverbindung"
        ); // "Network error: Please check your internet connection"
      } else {
        setError("Ein Fehler ist aufgetreten: " + err.message); // "An error occurred: " + err.message
      }
    }
  };

  const clearError = () => {
    setError(null);
  };

  return {
    authenticate,
    loading,
    error,
    isAuthenticated,
    setError,
    clearError,
  };
};

export default useAuth;
