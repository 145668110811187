import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { getAccessToken } from "../utils/auth";

const useFetchClients = () => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const accessToken = getAccessToken();

  const fetchClients = useCallback(async () => {
    if (!accessToken) return;

    setLoading(true);
    try {
      const response = await axios.get(`/api/v1/storage/clients`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
      if (response.data && Array.isArray(response.data)) {
        setClients(response.data);
      }
      setLoading(false);
    } catch (err) {
      console.error("Error fetching clients:", err);
      setError(err);
      setLoading(false);
    }
  }, [accessToken]);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  return { clients, loading, error };
};

export default useFetchClients;
