import React from "react";
import { Link } from "react-router-dom";
import styles from "./BoxItem.module.scss";

const BoxItem = ({ item }) => {
  const hasClientData = item.client && item.client.name;

  const linkTarget = hasClientData
    ? `project/${item._id}`
    : `/projectlist?searchParam=${item.name}`;

  return (
    <Link className={styles.projectListItem} to={linkTarget}>
      <div className={styles.imageContainer}>
        <div className={styles["project-name"]}>{item.name}</div>
      </div>
      {hasClientData && (
        <div className={styles.aboutContainer}>
          <div className={styles["client-name"]}>{item.client.name}</div>
        </div>
      )}
    </Link>
  );
};

export default BoxItem;
