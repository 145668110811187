import axios from "axios";

const HTTP_STATUS = {
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  UNPROCESSABLE_ENTITY: 422,
};

const ERROR_MESSAGES = {
  NOT_FOUND: "Einladung existiert nicht.",
  METHOD_NOT_ALLOWED:
    "Dieser Einladungslink ist entweder verwendet oder abgelaufen",
  UNPROCESSABLE_ENTITY: "Einladung ist nicht bearbeitbar.",
  DEFAULT: "Ein Fehler ist aufgetreten: ",
};

function handleApiError(error) {
  if (error.response) {
    switch (error.response.status) {
      case HTTP_STATUS.NOT_FOUND:
        throw new Error(ERROR_MESSAGES.NOT_FOUND);
      case HTTP_STATUS.METHOD_NOT_ALLOWED:
        throw new Error(ERROR_MESSAGES.METHOD_NOT_ALLOWED);
      case HTTP_STATUS.UNPROCESSABLE_ENTITY:
        throw new Error(ERROR_MESSAGES.UNPROCESSABLE_ENTITY);
      default:
        throw new Error(ERROR_MESSAGES.DEFAULT + error.message);
    }
  } else {
    throw new Error(ERROR_MESSAGES.DEFAULT + error.message);
  }
}

export async function fetchInvitationStatus(invitationId) {
  try {
    const response = await axios.get(`/api/v1/invitations/${invitationId}`);
    return { data: response.data, status: response.status };
  } catch (error) {
    handleApiError(error);
  }
}

export const updateInvitation = async (invitationId, password) => {
  try {
    const response = await axios.patch(`/api/v1/invitations/${invitationId}`, {
      status: "finished",
      password: password,
    });
    if (response.status === 200) {
      return response.data;
    }
    throw new Error("Failed to update invitation.");
  } catch (error) {
    console.error("Error updating invitation:", error.message);
    handleApiError(error);
  }
};
