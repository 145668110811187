import { useState, useCallback } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { getAccessToken } from "../utils/auth";

const useUpdateProjectPrice = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateProjectPrice = useCallback(async (projectId, priceText) => {
    setLoading(true);
    setError(null);
    const token = getAccessToken();
    const url = `/api/v1/storage/projects/${projectId}`;
    const data = {
      price_text: priceText,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await axios.patch(url, data, config);
      setLoading(false);
      toast.success("Data updated successfully");
      return response.data;
    } catch (error) {
      setError(error);
      setLoading(false);
      toast.error("Failed to upload data");
      throw error;
    }
  }, []);

  return { updateProjectPrice, loading, error };
};

export default useUpdateProjectPrice;
