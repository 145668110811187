import React, { useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import style from "./Breadcrumbs.module.css";

const Breadcrumbs = ({ currentProject }) => {
  const location = useLocation();

  const pathDisplayNames = useMemo(
    () => ({
      kundendokumente: "Kundendokumente",
      "giga-green": "Giga.Green",
      "vertrage-und-anmerkungen": "Verträge und Anmerkungen",
      "vertrage-und-angebote": "Verträge und Angebote",
      "vorlagen-informationen": "Informationen & Vorlagen",
      dienstleister: "Dienstleister",
    }),
    []
  );

  const crumbs = useMemo(() => {
    let currentLink = "";

    const parts = location.pathname.split("/").filter((crumb) => crumb !== "");

    return parts.map((crumb, index) => {
      currentLink = `${currentLink}/${crumb}`;
      let displayCrumb = crumb;
      if (index === 0 && currentProject.client.name) {
        displayCrumb = "Projekte";
      } else if (index === 1 && crumb === "project") {
        return null;
      } else if (index === 2 && currentProject.name) {
        displayCrumb = currentProject.name;
      } else if (index === 3) {
        displayCrumb = pathDisplayNames[crumb] || crumb.toUpperCase();
      }
      return (
        <div key={crumb} className={style.breadcrumb}>
          {currentProject.client.name && currentProject.name ? (
            <Link to={currentLink}>{displayCrumb}</Link>
          ) : (
            <div className={style.skeleton}></div>
          )}
        </div>
      );
    });
  }, [location, currentProject, pathDisplayNames]);

  return <div className={style.breadcrumbs}>{crumbs}</div>;
};

export default Breadcrumbs;
