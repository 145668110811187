import React from "react";
import { Link } from "react-router-dom";
import styles from "./ListItem.module.scss";

const ListItem = ({ item }) => {
  const hasClientData = item.client && item.client.name;

  const linkTarget = hasClientData
    ? `project/${item._id}`
    : `/projectlist?searchParam=${item.name}`;

  return (
    <Link
      className={styles.projectListItem}
      to={linkTarget}
      data-testid={item.name}
    >
      <div className={styles.section} data-testid={`listItem-${item.name}`}>
        <div className={styles["project-name"]}>{item.name}</div>
      </div>
      {hasClientData && (
        <div className={styles.section} data-testid={item.client.name}>
          <div className={styles["client-name"]}>{item.client.name}</div>
        </div>
      )}
    </Link>
  );
};

export default ListItem;
