import React, { useEffect, useState } from "react";
import styles from "./StatusChangeModal.module.css";

const StatusChangeModal = ({ show, onClose, onSave, currentStatus }) => {
  const [selectedStatus, setSelectedStatus] = useState(currentStatus);

  useEffect(() => {
    setSelectedStatus(currentStatus);
  }, [show, currentStatus]);

  if (!show) return null;

  const handleChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const handleSave = () => {
    onSave(selectedStatus);
    onClose();
  };

  return (
    <div className={styles["modal-overlay"]}>
      <div className={styles["modal-content"]} data-testid='status-modal'>
        <h2 className={styles.statusModalTitle}>Status ändern</h2>
        <select value={selectedStatus} onChange={handleChange}>
          <option value='new'>Neu</option>
          <option value='in_complete'>Unvollständig</option>
          <option value='in_progress'>in Bearbeitung</option>
          <option value='verified'>Verifiziert</option>
        </select>
        <div className={styles["buttons-wrapper"]}>
          <button className={styles["status-modal-button"]} onClick={onClose}>
            Schließen
          </button>
          <button
            className={styles["status-modal-button-save"]}
            onClick={handleSave}
          >
            Speichern
          </button>
        </div>
      </div>
    </div>
  );
};

export default StatusChangeModal;
