export const saveAccessToken = (token) => {
  localStorage.setItem("access_token", token);
};

export const getAccessToken = () => {
  return localStorage.getItem("access_token");
};

export const removeAccessToken = () => {
  localStorage.removeItem("access_token");
};

export const savePermissions = (permissions) => {
  localStorage.setItem("permissions", JSON.stringify(permissions));
};

export const getPermissions = () => {
  const permissions = localStorage.getItem("permissions");
  return permissions ? JSON.parse(permissions) : null;
};

export const removePermissions = () => {
  localStorage.removeItem("permissions");
};
