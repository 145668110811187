import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useS3Client } from "../hooks/useS3Client";
import Subcategory from "./Subcategory/Subcategory";
import { ClientIdContext } from "../contexts/ClientIdContext";

const S3ClientComponent = ({
  projectName,
  subcategories,
  category,
  isCommon,
  currentProject,
  oldNameMapping,
  permissions,
  selectedSubcategory,
  onFileUpload,
}) => {
  const { projectId } = useParams();
  const { clientId } = useContext(ClientIdContext);
  const {
    files,
    listObjects,
    updateFileComment,
    uploadFile,
    downloadFile,
    updateFileStatus,
    uploading,
    deleteFile,
    uploadProgress,
  } = useS3Client(projectId);
  useEffect(() => {
    listObjects();
  }, [listObjects]);
  return (
    <div style={{ width: "100%" }}>
      {subcategories.map((subcategory) => (
        <Subcategory
          selectedSubcategory={selectedSubcategory}
          oldNameMapping={oldNameMapping}
          key={subcategory}
          subcategory={subcategory}
          files={files}
          updateFileComment={updateFileComment}
          downloadFile={downloadFile}
          clientId={clientId}
          projectId={projectId}
          projectName={projectName}
          category={category}
          uploadFile={uploadFile}
          onUpdateFileStatus={updateFileStatus}
          uploading={uploading}
          isCommon={isCommon}
          currentProject={currentProject}
          deleteFile={deleteFile}
          permissions={permissions}
          uploadProgress={uploadProgress}
          onFileUpload={onFileUpload}
        />
      ))}
    </div>
  );
};

export default S3ClientComponent;
