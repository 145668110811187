import React from "react";
import { Link } from "react-router-dom";
import informationen_und_vorlagen from "../../images/information_icon.png";
import styles from "./TemplateItem.module.scss";

const TemplateItem = ({ item }) => {
  return (
    <Link
      className={styles.templateItem}
      to={`project/${item._id}`}
      data-testid='template-link'
    >
      <img
        src={informationen_und_vorlagen}
        alt='informationen_und_vorlagen'
        className={styles.informationIcon}
      />
      <div className={styles["client-name"]}>Informationen und Vorlagen</div>
    </Link>
  );
};

export default TemplateItem;
