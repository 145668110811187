import axios from "axios";
import { getAccessToken } from "../utils/auth";

export const changeUserPassword = async (newPassword) => {
  const accessToken = getAccessToken();

  try {
    const response = await axios.patch(
      `/api/v1/users/me`,
      { password: newPassword },
      { headers: { Authorization: `Bearer ${accessToken}` } }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message || "Error updating password.");
  }
};
