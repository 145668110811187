import React, { useState, useEffect } from "react";
import styles from "./Modal.module.css";
const Modal = ({ show, onClose, onSave, existingComment }) => {
  const [newComment, setNewComment] = useState(existingComment || "");
  const handleSubmit = () => {
    onSave(newComment);
    onClose();
  };

  useEffect(() => {
    setNewComment(existingComment === "null" ? "" : existingComment || "");
  }, [existingComment]);

  if (!show) {
    return null;
  }
  return (
    <div className={styles["modal-overlay"]}>
      <div className={styles["modal-content"]}>
        <div className={styles["title-button-wrapper"]}>
          <h2 data-testid='kommentar_hinzufugen_title'>Kommentar hinzufügen</h2>
          <div className={styles["close-button"]} onClick={onClose}>
            X
          </div>
        </div>
        <textarea
          className={styles["modal-textarea"]}
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          placeholder='Starte Eingabe ...'
        />
        <div className={styles["buttons-wrapper"]}>
          <button
            className={`${styles["modal-button"]} ${styles["modal-button-cancel"]}`}
            onClick={onClose}
          >
            Abbrechen
          </button>
          <button
            data-testid='kommentar_hinzufugen_button'
            className={`${styles["modal-button"]} ${styles["modal-button-save"]}`}
            onClick={handleSubmit}
          >
            Kommentar hinzufügen
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
