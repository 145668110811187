const subcategories = {
  Kundendokumente: [
    "Lastgang (Stromverbrauch)",
    "Stromabrechnung (aktueller Strompreis)",
    "Baupläne",
    "Standort Dokumente",
    "Statik",
    "Grundbucheinträge",
    "Sonstige",
  ],
  "Giga.Green": [
    "PV Sol",
    "Indikatives Angebot",
    "Finales Angebot",
    "Sonstige Projektdaten",
    "Sonstige",
  ],
  "Verträge und Angebote": [
    "Indikatives Angebot",
    "Nutzungsvereinbarung",
    "Stromliefervertrag",
    "Dienstbarkeitsvereinbarung",
    "Absichtserklärung",
    "Vollmachten",
  ],
  "Vorlagen & Informationen": [
    "Giga Green Informationen",
    "Vollmachten Vorlagen",
    "Sonstige",
  ],
  Dienstleister: [
    "Angebot/Vertrag",
    "Projektmanagement",
    "Anmeldung/NVP/Bauantrag",
    "Anlagendokumentation",
    "Inbetriebnahme",
    "Sonstige",
  ],
};

export default subcategories;
