import React, { useContext } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import styles from "./Layout.module.scss";
import Sidebar from "../Sidebar/Sidebar";
import LoginContext from "../../contexts/LoginContext";

const Layout = ({ children }) => {
  const { isLoggedIn } = useContext(LoginContext);

  return (
    <div className={styles.layout}>
      {isLoggedIn && <Sidebar />}
      <div className={styles.contentColumn}>
        <Header />
        <div className={styles.content}>
          <main className={styles.main}>{children}</main>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
