import React, { useState } from "react";
import styles from "./PasswordChangeForm.module.css";
import { changeUserPassword } from "../../api/userApi";
import { isPasswordStrong } from "../../utils/passwordUtils";

function PasswordChangeForm() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [updateError, setUpdateError] = useState(null);
  const [isSuccessful, setIsSuccessful] = useState(false);

  const handlePasswordChange = (e) => {
    setUpdateError(null);
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setUpdateError(null);
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isPasswordStrong(password)) {
      setUpdateError(
        "Das Passwort sollte mindestens einen Kleinbuchstaben, einen Großbuchstaben, eine Ziffer und mindestens 8 Zeichen enthalten."
      );
      return;
    }

    if (password !== confirmPassword) {
      setUpdateError("Passwörter stimmen nicht überein.");
      return;
    }

    setLoading(true);
    try {
      await changeUserPassword(password);
      setIsSuccessful(true);
    } catch (error) {
      setUpdateError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles["form-wrapper"]}>
      {isSuccessful ? (
        <p data-testid='password-updated'>Passwort erfolgreich aktualisiert!</p>
      ) : (
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles["input-wrapper"]}>
            <label htmlFor='new-password'>Neues Passwort:</label>
            <input
              id='new-password'
              type='password'
              value={password}
              onChange={handlePasswordChange}
              className={styles.input}
            />
          </div>
          <div className={styles["input-wrapper"]}>
            <label htmlFor='confirm-password'>Passwort bestätigen:</label>
            <input
              id='confirm-password'
              type='password'
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              className={styles.input}
            />
          </div>
          {updateError && (
            <div className={styles.error} data-testid='update-error'>
              {updateError}
            </div>
          )}
          {loading && <p>Passwort wird aktualisiert...</p>}
          <button
            type='submit'
            className={styles.button}
            disabled={loading}
            data-testid='password-change-button'
          >
            Passwort aktualisieren
          </button>
        </form>
      )}
    </div>
  );
}

export default PasswordChangeForm;
