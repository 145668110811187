import React, { useEffect, useRef, useState } from "react";
import useUpdateProjectPrice from "../../hooks/useUpdateProjectPrice";
import FileDropzone from "../FileDropzone/FileDropzone";
import List from "../List/List";
import styles from "./Subcategory.module.css";

const Subcategory = ({
  selectedSubcategory,
  oldNameMapping,
  subcategory,
  files,
  updateFileComment,
  downloadFile,
  clientId,
  projectId,
  category,
  uploadFile,
  onUpdateFileStatus,
  isCommon,
  currentProject,
  deleteFile,
  permissions,
  uploadProgress,
  onFileUpload,
}) => {
  const [accordionVisible, setAccordionVisible] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const [price, setPrice] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const contentRef = useRef(null);
  const { updateProjectPrice } = useUpdateProjectPrice();
  const handlePriceChange = (event) => setPrice(event.target.value);
  const handlePriceSubmit = (event) => {
    event.preventDefault();
    updateProjectPrice(projectId, price);
  };
  const refreshFiles = () => onFileUpload();
  const toggleAccordion = () => setAccordionVisible(!accordionVisible);
  const openAccordion = () => setAccordionVisible(true);
  const closeAccordion = () => setAccordionVisible(false);

  const oldSubcategoryName =
    Object.keys(oldNameMapping).find(
      (key) => oldNameMapping[key] === subcategory
    ) || subcategory;
  const newSubcategoryName = oldNameMapping[subcategory] || subcategory;

  const filteredFiles = files.filter(
    (file) =>
      file.category === category &&
      (file.subcategory === oldSubcategoryName ||
        file.subcategory === newSubcategoryName)
  );
  const templateProject = permissions.can_edit_common_project || !isCommon;
  const emptyListText = templateProject
    ? "Kategorie leer, bitte laden Sie Ihre Dateien hoch."
    : "Kategorie leer.";

  useEffect(
    () => setPrice(currentProject.price_text),
    [currentProject.price_text]
  );

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setContentHeight(
      accordionVisible && contentRef.current
        ? contentRef.current.scrollHeight + (windowWidth <= 768 ? 500 : 0)
        : 0
    );
  }, [files, accordionVisible, windowWidth]);

  useEffect(
    () =>
      selectedSubcategory === subcategory ? openAccordion() : closeAccordion(),
    [selectedSubcategory, subcategory]
  );

  return (
    <div style={{ marginBottom: "20px" }}>
      <h3
        className={styles["subcategory-title"]}
        onClick={toggleAccordion}
        data-testid='subcategory-dropdown'
      >
        {accordionVisible ? "▼" : "►"} {subcategory}
      </h3>
      <div
        className={
          accordionVisible
            ? `${styles.content} ${styles.show}`
            : `${styles.content}`
        }
        style={accordionVisible ? { maxHeight: `${contentHeight}px` } : {}}
        ref={contentRef}
      >
        {(permissions.role === "admin" ||
          permissions.role === "manager" ||
          permissions.role === "customer") &&
          category === "Kundendokumente" &&
          subcategory === "Stromabrechnung (aktueller Strompreis)" && (
            <div className='price-form-wrapper'>
              <p>
                Geben Sie Ihren Strompreis manuell ein oder laden Sie einfach
                eine Datendatei hoch. Wählen Sie, was für Sie am bequemsten ist!
              </p>
              <form
                onSubmit={handlePriceSubmit}
                className={styles["price-form"]}
              >
                <input
                  placeholder='Zum Beispiel €0,36 pro KWh'
                  type='text'
                  value={price}
                  onChange={handlePriceChange}
                  className={styles["price-input"]}
                  data-testid='price-input'
                />
                <button
                  type='submit'
                  className={styles["save-button"]}
                  data-testid='price-submit'
                >
                  Speichern
                </button>
              </form>
            </div>
          )}
        {filteredFiles.length > 0 && (
          <List
            files={filteredFiles}
            onUpdateFileComment={updateFileComment}
            onDownloadFile={downloadFile}
            clientId={clientId}
            projectId={projectId}
            onUpdateFileStatus={onUpdateFileStatus}
            templateProject={templateProject}
            currentProject={currentProject}
            deleteFile={deleteFile}
            subcategory={subcategory}
          />
        )}
        {!filteredFiles.length && (
          <div data-testid={subcategory} className='empty-list'>
            {emptyListText}
          </div>
        )}
        {templateProject && (
          <FileDropzone
            uploadProgress={uploadProgress}
            onUploadComplete={refreshFiles}
            onDrop={(files) =>
              uploadFile(files, projectId, clientId, subcategory, category)
            }
          />
        )}
      </div>
    </div>
  );
};

export default Subcategory;
