import { useState, useEffect, useContext } from "react";
import { getAccessToken } from "../utils/auth";
import LoginContext from "../contexts/LoginContext";

const useFetchSingleProject = (projectId) => {
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { isLoggedIn } = useContext(LoginContext);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const token = getAccessToken();
        const response = await fetch(`/api/v1/storage/projects/${projectId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
          },
        });
        if (!response.ok) {
          const error = new Error(response.statusText);
          error.status = response.status;
          throw error;
        }

        const project = await response.json();
        setProject(project);
      } catch (err) {
        setError({ message: err.message, status: err.status });
      } finally {
        setLoading(false);
      }
    };

    fetchProject();
  }, [projectId, isLoggedIn]);
  return { project, loading, error };
};

export default useFetchSingleProject;
