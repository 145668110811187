import { createContext, useState, useEffect } from "react";
import {
  getAccessToken,
  saveAccessToken,
  removeAccessToken,
  removePermissions,
} from "../utils/auth";
import { redirect } from "react-router-dom";
import { removeCookie } from "../utils/cookies";

const LoginContext = createContext();

export const LoginProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  useEffect(() => {
    const token = getAccessToken();
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const toggleLoginModal = () => {
    setIsLoginModalOpen(!isLoginModalOpen);
  };

  const logIn = (token) => {
    saveAccessToken(token);
    setIsLoggedIn(true);
  };

  const logOut = () => {
    removeAccessToken();
    removePermissions();
    removeCookie("permissions");
    setIsLoggedIn(false);
    redirect("/");
  };

  return (
    <LoginContext.Provider
      value={{
        isLoggedIn,
        isLoginModalOpen,
        toggleLoginModal,
        logIn,
        logOut,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export default LoginContext;
