import React from "react";
import styles from "./SearchBar.module.css";
import iconPath from "./../../images/glass.png";

const SearchBar = ({ searchTerm, setSearchTerm, searchPlaceholder }) => {
  return (
    <div className={styles.searchWrapper}>
      <div className={styles.searchContainer}>
        <input
          type='text'
          placeholder={searchPlaceholder}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className={styles.searchBar}
          aria-label={searchPlaceholder}
          data-testid='search-bar'
        />
        <img src={iconPath} alt='Search Icon' className={styles.searchIcon} />
      </div>
    </div>
  );
};

export default SearchBar;
