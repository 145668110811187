import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import LoginContext from "../../contexts/LoginContext";
import { PermissionsContext } from "../../contexts/PermissionsContext";

const ProtectedRoute = ({ children, allowedRoles }) => {
  const { isLoggedIn } = useContext(LoginContext);
  const { permissions } = useContext(PermissionsContext);
  const userRole = permissions.role;
  const location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to='/' state={{ from: location }} replace />;
  }

  if (allowedRoles && !allowedRoles.includes(userRole)) {
    return <Navigate to='/projectlist' replace />;
  }

  return children;
};

export default ProtectedRoute;
