import React, { useContext, useEffect, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useParams,
} from "react-router-dom";
import Navbar from "../components/Navbar/Navbar";
import S3ClientComponent from "../components/S3ClientComponent";
import Title from "../components/Title/Title";
import defaultRoutes from "../constants/defaultRoutes";
import oldNameMapping from "../constants/oldNameMapping";
import subcategories from "../constants/subcategories";
import supplierRoles from "../constants/supplierRoles";
import { ClientIdContext } from "../contexts/ClientIdContext";
import { usePermissions } from "../contexts/PermissionsContext";
import useFetchSingleProject from "../hooks/useFetchSingleProject";
import { useS3Client } from "../hooks/useS3Client";

const ProjectPage = () => {
  const { projectId } = useParams();
  const { permissions } = usePermissions();
  const { files, listObjects } = useS3Client(projectId);
  const { clientId } = useContext(ClientIdContext);
  const {
    project: currentProject,
    loading: isLoading,
    error,
  } = useFetchSingleProject(projectId);
  const handleSearchResults = (results) => setSearchResults(results);
  const handleFileUpload = () => listObjects();
  const navigate = useNavigate();
  const [searchResults, setSearchResults] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);

  useEffect(() => {
    listObjects();
  }, [listObjects]);

  useEffect(() => {
    if (!isLoading && currentProject && currentProject.common) {
      navigate(`/projectlist/project/${projectId}/vorlagen-informationen`, {
        replace: true,
      });
    }
  }, [isLoading, currentProject, projectId, navigate]);

  if (isLoading) {
    return <div className='container'>Laden...</div>;
  }

  const checkPermission = (roles) => {
    if (!roles.includes(permissions.role)) {
      if (supplierRoles.includes(permissions.role)) {
        return (
          <Navigate
            to={`/projectlist/project/${projectId}/dienstleister`}
            replace
          />
        );
      } else {
        return (
          <Navigate
            to={`/projectlist/project/${projectId}/${
              defaultRoutes[permissions.role]
            }`}
            replace
          />
        );
      }
    }
  };

  if (error) {
    if (error.status === 500) {
      return (
        <div className='container'>
          <div
            style={{
              padding: "10px 25px",
              borderRadius: "10px",
              border: "1px solid #043528",
              gap: "8px",
              color: "#043528",
              backgroundColor: "#C6E5D5",
            }}
            data-testid='serwer-error-message'
          >
            Es tut uns leid, aber es ist ein interner Serverfehler aufgetreten.
            Bitte versuchen Sie es später erneut oder kontaktieren Sie unseren
            Support unter service@giga.green, falls das Problem weiterhin
            besteht.
          </div>
        </div>
      );
    } else if (error.status === 403) {
      return (
        <div className='container'>
          <div
            style={{
              padding: "10px 25px",
              borderRadius: "10px",
              border: "1px solid #043528",
              gap: "8px",
              color: "#043528",
              backgroundColor: "#C6E5D5",
            }}
            data-testid='no-access-message'
          >
            Wir entschuldigen uns für die Unannehmlichkeiten, aber es scheint,
            dass Sie derzeit keinen Zugriff auf das angeforderte Konto/Projekt
            haben. Wenn Sie der Meinung sind, dass Sie Zugriff haben sollten,
            wenden Sie sich bitte an Ihren Account-Betreuer oder kontaktieren
            Sie unsere Service-E-Mail unter service@giga.green.
          </div>
        </div>
      );
    }
  }
  if (!currentProject) {
    return (
      <div className='container' data-testid='no-project-message'>
        Project not found or error in fetching.
      </div>
    );
  }
  return (
    <div>
      <div className='container'>
        <Title
          clientName={currentProject?.client?.name}
          projectName={currentProject.name}
          commonProject={currentProject.common}
        />
      </div>
      <Navbar
        currentProject={currentProject}
        files={files}
        onSearch={handleSearchResults}
        clientId={clientId}
        projectId={projectId}
        permissions={permissions}
        setSelectedSubcategory={setSelectedSubcategory}
        handleSearchResults={handleSearchResults}
        searchResults={searchResults}
      />
      <main className='main'>
        <div className='container'>
          <Routes>
            <Route
              path='/'
              element={
                <Navigate
                  to={defaultRoutes[permissions.role] || "kundendokumente"}
                  replace
                />
              }
              index
            />
            <Route
              path='kundendokumente'
              element={
                <>
                  {checkPermission(["admin", "manager", "customer"])}
                  <S3ClientComponent
                    oldNameMapping={oldNameMapping}
                    projectId={projectId}
                    projectName={currentProject.name}
                    subcategories={subcategories["Kundendokumente"]}
                    category='Kundendokumente'
                    isCommon={currentProject?.common || false}
                    currentProject={currentProject}
                    permissions={permissions}
                    selectedSubcategory={selectedSubcategory}
                    onFileUpload={handleFileUpload}
                  />
                </>
              }
            />
            <Route
              path='giga-green'
              element={
                <>
                  {checkPermission(["admin", "manager"])}
                  <S3ClientComponent
                    oldNameMapping={oldNameMapping}
                    projectId={projectId}
                    projectName={currentProject.name}
                    subcategories={subcategories["Giga.Green"]}
                    category='Giga.Green'
                    isCommon={currentProject?.common || false}
                    currentProject={currentProject}
                    permissions={permissions}
                    selectedSubcategory={selectedSubcategory}
                    onFileUpload={handleFileUpload}
                  />
                </>
              }
            />
            <Route
              path='vertrage-und-angebote'
              element={
                <>
                  {checkPermission(["admin", "manager", "customer"])}
                  <S3ClientComponent
                    oldNameMapping={oldNameMapping}
                    projectId={projectId}
                    subcategories={subcategories["Verträge und Angebote"]}
                    category='Verträge und Angebote'
                    projectName={currentProject.name}
                    isCommon={currentProject?.common || false}
                    currentProject={currentProject}
                    permissions={permissions}
                    selectedSubcategory={selectedSubcategory}
                    onFileUpload={handleFileUpload}
                  />
                </>
              }
            />
            <Route
              path='vorlagen-informationen'
              element={
                <>
                  {currentProject?.common
                    ? null
                    : checkPermission(["admin", "manager", "customer"])}
                  <S3ClientComponent
                    oldNameMapping={oldNameMapping}
                    projectId={projectId}
                    projectName={currentProject.name}
                    subcategories={subcategories["Vorlagen & Informationen"]}
                    category='Vorlagen & Informationen'
                    isCommon={currentProject?.common || false}
                    currentProject={currentProject}
                    permissions={permissions}
                    selectedSubcategory={selectedSubcategory}
                    onFileUpload={handleFileUpload}
                  />
                </>
              }
            />
            <Route
              path='dienstleister'
              element={
                <>
                  {checkPermission([
                    "admin",
                    "manager",
                    "supplier",
                    "solar_engineer",
                    "structural_engineer",
                    "electrician",
                  ])}
                  <S3ClientComponent
                    oldNameMapping={oldNameMapping}
                    projectId={projectId}
                    subcategories={subcategories["Dienstleister"]}
                    category='Dienstleister'
                    projectName={currentProject.name}
                    isCommon={currentProject?.common || false}
                    currentProject={currentProject}
                    permissions={permissions}
                    selectedSubcategory={selectedSubcategory}
                    onFileUpload={handleFileUpload}
                  />
                </>
              }
            />
            <Route
              path='vertrage-und-anmerkungen'
              element={<Navigate to='../vertrage-und-angebote' replace />}
            />
            <Route
              path='zulieferer'
              element={<Navigate to='../dienstleister' replace />}
            />
          </Routes>
        </div>
      </main>
    </div>
  );
};

export default ProjectPage;
