import React from "react";
import style from "./Footer.module.css";
import linkedinIcon from "../../images/in_icon.svg";
import xingIcon from "../../images/xg_icon.svg";

const Footer = () => {
  return (
    <div className={style["footer-container"]}>
      <div className={style["footer-bottom"]}>
        <div className={style["footer-links"]}>
          <a
            target='_blank'
            rel='noreferrer'
            href='https://www.giga.green/impressum'
            className={style["footer-link-single"]}
          >
            Impressum
          </a>
          <a
            target='_blank'
            rel='noreferrer'
            href='https://www.giga.green/datenschutz'
            className={style["footer-link-single"]}
          >
            Datenschutz
          </a>
        </div>
        <div className={style["socials-collection"]}>
          <div className={style["socials-list"]}>
            <div className={style["social-item"]}>
              <a
                href='https://www.linkedin.com/company/gigagreen/'
                target='_blank'
                rel='noreferrer'
                className={style["socials-link"]}
              >
                <img
                  src={linkedinIcon}
                  loading='lazy'
                  alt='linkedin icon'
                  className={style["socials-icon"]}
                />
              </a>
            </div>
            <div className={style["social-item"]}>
              <a
                href='https://www.xing.com/pages/giga-green'
                target='_blank'
                rel='noreferrer'
                className={style["socials-link"]}
              >
                <img
                  src={xingIcon}
                  loading='lazy'
                  alt='xing icon'
                  className={style["socials-icon"]}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
