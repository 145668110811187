import React from "react";
import styles from "./ModalComponent.module.css";

const ModalComponent = ({ show, onClose, children, title, footer }) => {
  if (!show) {
    return null;
  }
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.titleButtonWrapper}>
          <h2>{title || "Default Title"}</h2>
          <div className={styles.closeButton} onClick={onClose}>
            X
          </div>
        </div>
        <div className={styles.modalBody}>{children}</div>
        {footer && <div className={styles.buttonsWrapper}>{footer}</div>}
      </div>
    </div>
  );
};

export default ModalComponent;
