import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// react-router
import {
  Navigate,
  Outlet,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";

//styles
import "./App.scss";

//contexts
import { ClientIdProvider } from "./contexts/ClientIdContext";
import { LoginProvider } from "./contexts/LoginContext";
import { PermissionsProvider } from "./contexts/PermissionsContext";

//components
import Layout from "./components/Layout/Layout";
import LoginModal from "./components/LoginModal/LoginModal";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";

//pages
import ClientPage from "./pages/ClientPage";
import ErrorPage from "./pages/ErrorPage";
import InvitationPage from "./pages/InvitationPage";
import LoginPage from "./pages/LoginPage";
import ProjectPage from "./pages/ProjectPage";
import AccountsPage from "./pages/AccountsPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/panel/invitations/:uuid",
    element: <InvitationPage />,
    errorElement: (
      <Layout>
        <ErrorPage />
      </Layout>
    ),
  },
  {
    path: "/projectlist/*",
    element: (
      <ProtectedRoute>
        <Layout>
          <ClientPage />
        </Layout>
      </ProtectedRoute>
    ),
    errorElement: (
      <Layout>
        <ErrorPage />
      </Layout>
    ),
  },
  {
    path: "/projectlist/project/:projectId/*",
    element: (
      <ProtectedRoute>
        <Layout>
          <ProjectPage />
        </Layout>
      </ProtectedRoute>
    ),
    errorElement: (
      <Layout>
        <ErrorPage />
      </Layout>
    ),
  },
  {
    path: "/accountlist/*",
    element: (
      <ProtectedRoute allowedRoles={["manager", "admin"]}>
        <Layout>
          <AccountsPage />
        </Layout>
      </ProtectedRoute>
    ),
    errorElement: (
      <Layout>
        <ErrorPage />
      </Layout>
    ),
  },
  {
    path: "*",
    element: <Navigate to='/' replace />,
  },
]);

const LayoutWithOutlet = () => (
  <Layout>
    <Outlet />
  </Layout>
);

function App() {
  return (
    <LoginProvider>
      <PermissionsProvider>
        <ClientIdProvider>
          <ToastContainer />
          <RouterProvider router={router}>
            <div className='App'>
              <LayoutWithOutlet />
              <LoginModal />
            </div>
          </RouterProvider>
        </ClientIdProvider>
      </PermissionsProvider>
    </LoginProvider>
  );
}

export default App;
