import React from "react";
import styles from "./Title.module.css";

const Title = ({ clientName, projectName, commonProject }) => {
  const isLoading = !clientName || !projectName;

  return (
    <div>
      {commonProject ? (
        <h1 className={styles["header-title"]}>Informationen und Vorlagen</h1>
      ) : (
        <h1 className={styles["header-title"]}>
          {isLoading ? (
            <div className={styles.skeleton}></div>
          ) : (
            `${clientName} - Projekt: ${projectName}`
          )}
        </h1>
      )}
    </div>
  );
};

export default Title;
