//react
import React, { useContext, useEffect, useState } from "react";

//routing
import { useLocation, useNavigate } from "react-router-dom";

//contexts
import { ClientIdContext } from "../contexts/ClientIdContext";
import LoginContext from "../contexts/LoginContext";

//custom hooks
import useAuth from "../hooks/useAuth";
import useFetchClients from "../hooks/useFetchClients";
import useFetchUser from "../hooks/useFetchUser";

//components and assets
import LoginPageLayout from "../components/LoginPageLayout/LoginPageLayout";
import LoginForm from "../components/LoginForm/LoginForm";
import InfoBox from "../components/Infobox/Infobox";

//utility functions
import { getAccessToken } from "../utils/auth";

const LoginPage = () => {
  const initialToken = getAccessToken();
  const location = useLocation();
  const navigate = useNavigate();

  const [accessToken, setAccessToken] = useState(null);
  const { setClientId } = useContext(ClientIdContext);
  const { logIn, isLoggedIn } = useContext(LoginContext);
  const { clients } = useFetchClients(accessToken);

  const auth = useAuth();
  const { userData } = useFetchUser(accessToken, isLoggedIn);

  useEffect(() => {
    if (initialToken && !isLoggedIn) {
      logIn(initialToken);
      setAccessToken(initialToken);
    }
  }, [initialToken, logIn, isLoggedIn]);

  const handleLogin = async (email, password) => {
    try {
      const response = await auth.authenticate(email, password);
      if (response && response.access_token) {
        logIn(response.access_token);
        setAccessToken(response.access_token);
      }
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    const handleClientRedirection = async () => {
      if (isLoggedIn && userData) {
        await setClientId(userData.client_id);
        const redirectPath =
          location.state?.from?.pathname ||
          (userData.role === "admin" || userData.role === "manager"
            ? "/accountlist"
            : "/projectlist");
        navigate(redirectPath);
      }
    };
    handleClientRedirection();
  }, [
    isLoggedIn,
    clients,
    navigate,
    setClientId,
    userData,
    location.state?.from?.pathname,
  ]);

  return (
    <LoginPageLayout>
      <LoginForm
        onLogin={handleLogin}
        error={auth.error}
        clearError={auth.clearError}
      />
      <InfoBox />
    </LoginPageLayout>
  );
};

export default LoginPage;
