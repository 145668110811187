import { useState, useEffect } from "react";
import { fetchInvitationStatus } from "../api/invitationsApi";

export const useInvitationData = (uuid) => {
  const [data, setData] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    async function fetchInvitation() {
      try {
        const { data: invitationData, status: responseStatus } =
          await fetchInvitationStatus(uuid);

        if (responseStatus === 200 && invitationData.status === "active") {
          setData(invitationData);
        } else {
          setIsActive(false);
        }
      } catch (error) {
        console.error("Failed to fetch the invitation:", error);
        setIsActive(false);
        setErrorMessage(error.message);
      } finally {
        setIsLoading(false);
      }
    }

    fetchInvitation();
  }, [uuid]);

  return { data, isActive, isLoading, errorMessage };
};
