import React, { useContext } from "react";
import logo from "../../images/gigahub-logo.png";
import styles from "./Sidebar.module.scss";
import { NavLink } from "react-router-dom";
import InfoBox from "../Infobox/Infobox";
import { PermissionsContext } from "../../contexts/PermissionsContext";

const Sidebar = () => {
  const { permissions } = useContext(PermissionsContext);
  const userRole = permissions.role;
  return (
    <aside className={styles.sidebar}>
      <div className={styles.sidebarContent}>
        <div className={styles.logo_wrapper}>
          <img src={logo} alt='Logo' />
        </div>
        <div className={styles.sideBarMenu}>
          {(userRole === "manager" || userRole === "admin") && (
            <NavLink
              to={`/accountlist`}
              className={styles.sideBarMenuItem}
              data-testid='accountlist-link'
            >
              Accounts
            </NavLink>
          )}
          <NavLink
            to={`/projectlist`}
            className={styles.sideBarMenuItem}
            data-testid='projectlist-link'
          >
            Projekte
          </NavLink>
        </div>
      </div>
      <InfoBox />
    </aside>
  );
};

export default Sidebar;
