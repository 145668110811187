import React, { createContext, useEffect, useState } from "react";

const ClientIdContext = createContext();

const ClientIdProvider = ({ children }) => {
  const [clientId, setClientId] = useState(
    localStorage.getItem("clientId") || null
  );

  useEffect(() => {
    if (clientId) {
      localStorage.setItem("clientId", clientId);
    } else {
      localStorage.removeItem("clientId");
    }
  }, [clientId]);
  return (
    <ClientIdContext.Provider value={{ clientId, setClientId }}>
      {children}
    </ClientIdContext.Provider>
  );
};

export { ClientIdContext, ClientIdProvider };
