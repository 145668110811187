import React from "react";
import styles from "./Status.module.css";
import verified from "../../images/approved.png";
import rejected from "../../images/rejected.png";
import pending from "../../images/pending.png";
import incoming from "../../images/incoming.png";

const statusMap = {
  new: { src: incoming, alt: "new" },
  verified: { src: verified, alt: "verified" },
  in_progress: { src: pending, alt: "in_progress" },
  in_complete: { src: rejected, alt: "in_complete" },
};

const Status = ({ status, onClick }) => {
  const { src, alt } = statusMap[status] || statusMap["new"];

  return (
    <span className={styles.icon} onClick={onClick} data-testid='status-button'>
      <img src={src} alt={alt} />
    </span>
  );
};

export default Status;
