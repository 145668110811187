import React from "react";
import { Link, useRouteError } from "react-router-dom";
import style from "./ErrorPage.module.scss";

const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);
  return (
    <div className='container' id='error-page'>
      <div className={style.errorContent}>
        <h1 className={style.errorTitle}>Seite nicht gefunden</h1>
        <p className={style.errorMessage}>
          Die Seite, die Sie suchen, existiert nicht.
        </p>
        <Link to='/projectlist' className={style.backLink}>
          Zurück zur Startseite
        </Link>
      </div>
    </div>
  );
};

export default ErrorPage;
