import React, { useEffect, useState } from "react";
import ListItem from "../ListItem/ListItem";
import styles from "./ItemsList.module.scss";
import SearchBar from "../SearchBar/SearchBar";
import gridLayoutIcon from "../../images/grid.png";
import listLayoutIcon from "../../images/list.png";
import Tooltip from "../Tooltip/Tooltip";
import TemplateItem from "../TemplateItem/TemplateItem";
import BoxItem from "../BoxItem/BoxItem";

const ItemsList = ({
  listTitle,
  listSubtitle,
  items,
  loading,
  error,
  commonProject,
  searchTerm,
  setSearchTerm,
  noItemsMessage,
  noItemsFoundMessage,
  searchPlaceholder,
}) => {
  const [isListView, setIsListView] = useState(
    () => JSON.parse(localStorage.getItem("isListView")) ?? true
  );
  const toggleView = () => {
    setIsListView(!isListView);
  };
  const ItemComponent = isListView ? ListItem : BoxItem;
  const layoutIcon = isListView ? gridLayoutIcon : listLayoutIcon;
  useEffect(() => {
    localStorage.setItem("isListView", JSON.stringify(isListView));
  }, [isListView]);
  return (
    <div className={styles.wrapper}>
      <div className={styles["template-header-container"]}>
        <div className={styles["template-wrapper"]}>
          {commonProject && (
            <div>
              <TemplateItem
                key={commonProject?._id}
                item={commonProject}
                templates={true}
              />
            </div>
          )}
        </div>
      </div>
      <div className={styles.tableHeader}>
        <div className={styles.tableTitleWrapper}>
          <div data-testid={listTitle} className={styles.tableTitle}>
            {listTitle}
          </div>
          <p data-testid={`${listTitle}-subtitle`}>{listSubtitle}</p>
        </div>

        <div className={styles.tableOptionsWrapper}>
          <div className={styles.options}>
            <SearchBar
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              searchPlaceholder={searchPlaceholder}
            />
            <Tooltip content='Listenansicht ändern'>
              <button className={styles.toggleView} onClick={toggleView}>
                <img src={layoutIcon} alt='Layout Change Icon' />
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
      <div
        className={`${styles.projectList} ${
          isListView ? styles.list : styles.box
        }`}
        data-testid={`${listTitle}-list`}
      >
        {loading ? (
          <div>Laden...</div>
        ) : error ? (
          <div>Error: {error.message}</div>
        ) : items.length === 0 ? (
          searchTerm ? (
            <div className={styles.noItemsMessage}>{noItemsFoundMessage}</div>
          ) : (
            <div className={styles.noItemsMessage}>{noItemsMessage}</div>
          )
        ) : (
          items.map((item) => <ItemComponent key={item._id} item={item} />)
        )}
      </div>
    </div>
  );
};

export default ItemsList;
