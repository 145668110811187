import React from "react";
import icon from "../../images/questionmark-icon.png";
import styles from "./Infobox.module.scss";

const InfoBox = () => {
  return (
    <div className={styles.infoBoxWrapper} data-testid='infobox'>
      <div className={styles.infoBox}>
        <div className={styles.iconContainer}>
          <img src={icon} alt='questionmark' />
        </div>
        <div className={styles.infoBoxTitle}>
          Brauchen Sie Hilfe mit Giga:Hub? Kontaktieren Sie uns
        </div>
        <a href='mailto:service@giga.green' className={styles.infoBoxButton}>
          service@giga.green
        </a>
      </div>
    </div>
  );
};

export default InfoBox;
