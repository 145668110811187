import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./FileSearch.module.css";
import iconPath from "./../../images/glass.png";
import supplierRoles from "../../constants/supplierRoles";

const FileSearch = ({
  files,
  clientId,
  projectId,
  onSubcategorySelect,
  permissions,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [focusedSuggestionIndex, setFocusedSuggestionIndex] = useState(null);
  const debounceTimeout = useRef(null);
  const suggestionsRef = useRef(null);
  const navigate = useNavigate();
  const searchInputRef = useRef(null);

  const formatForURL = (str) => {
    return str
      .toLowerCase()
      .replace(/ä/g, "a")
      .replace(/ö/g, "o")
      .replace(/ü/g, "u")
      .replace(/ß/g, "ss")
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/(^-|-$)/g, "");
  };

  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      if (searchTerm) {
        let filtered = files.filter((file) =>
          file.name.toLowerCase().includes(searchTerm.toLowerCase())
        );

        if (permissions.role === "customer") {
          filtered = filtered.filter(
            (file) =>
              file.category === "Kundendokumente" ||
              file.category === "Verträge und Angebote"
          );
        } else if (supplierRoles.includes(permissions.role)) {
          filtered = filtered.filter(
            (file) => file.category === "Dienstleister"
          );
        }
        setSuggestions(filtered.slice(0, 5));
      } else {
        setSuggestions([]);
      }
    }, 300);

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [searchTerm, files, permissions.role]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "ArrowDown") {
        setFocusedSuggestionIndex((prev) =>
          prev === null || prev === suggestions.length - 1 ? 0 : prev + 1
        );
      } else if (e.key === "ArrowUp") {
        setFocusedSuggestionIndex((prev) =>
          prev === null || prev === 0 ? suggestions.length - 1 : prev - 1
        );
      } else if (e.key === "Enter" && focusedSuggestionIndex !== null) {
        const selectedFile = suggestions[focusedSuggestionIndex];
        if (selectedFile) {
          setSearchTerm("");
          setSuggestions([]);
          onSubcategorySelect(selectedFile.subcategory);
          navigate(
            `/projectlist/project/${projectId}/${formatForURL(
              selectedFile.category
            )}`
          );
        }
      } else if (e.key === "Escape") {
        setSearchTerm("");
        setSuggestions([]);
        setFocusedSuggestionIndex(null);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    focusedSuggestionIndex,
    suggestions,
    onSubcategorySelect,
    clientId,
    projectId,
    navigate,
  ]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target)
      ) {
        setSuggestions([]);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className={styles.searchWrapper}>
      <div className={styles.searchContainer}>
        <input
          ref={searchInputRef}
          type='text'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder='Nach einer Datei suchen...'
          className={styles.searchBar}
        />
        <img src={iconPath} alt='Search Icon' className={styles.searchIcon} />
        {suggestions.length > 0 && (
          <div className={styles.suggestionsContainer} ref={suggestionsRef}>
            {suggestions.map((file, index) => (
              <Link
                to={`/projectlist/project/${projectId}/${formatForURL(
                  file.category
                )}`}
                key={file._id}
                className={`${styles.suggestion} ${
                  index === focusedSuggestionIndex ? styles.focused : ""
                }`}
                onClick={() => {
                  setSuggestions([]);
                  onSubcategorySelect(file.subcategory);
                  setSearchTerm("");
                }}
              >
                {file.name} - <strong>{file.category}</strong> -{" "}
                {file.subcategory}
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default FileSearch;
