import axios from "axios";
import { useCallback, useState } from "react";
import { getAccessToken } from "../utils/auth";
import { toast } from "react-toastify";

export const useS3Client = (companyId) => {
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const updateFileComment = async (fileId, newComment) => {
    try {
      const token = getAccessToken();
      const response = await axios.put(
        `/api/v1/storage/files/${fileId}?comment=${encodeURIComponent(
          newComment
        )}`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.status === 200) {
        toast.success("Dateikommentar erfolgreich aktualisiert");
        listObjects();
      } else {
        console.error("Error updating file comment:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating file comment:", error);
    }
  };

  const listObjects = useCallback(async () => {
    try {
      const token = getAccessToken();
      const response = await axios.get(
        `/api/v1/storage/projects/${companyId}/files`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const data = response.data;
      setFiles(data);
    } catch (error) {
      console.error("Error listing files:", error);
    }
  }, [companyId]);

  const getPresignedUrl = async (filePath, operation_type) => {
    const operations_type = operation_type || "put_object";
    const response = await axios.get(
      `/api/v1/storage/get_presigned_url?filename=${filePath}&operation_type=${operations_type}`
    );

    return response.data;
  };

  const uploadFileToS3 = async (presignedUrl, file) => {
    try {
      await axios.put(presignedUrl, file, {
        headers: {
          "Content-Type": file.type,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      });
      return true;
    } catch (error) {
      console.error("Error uploading file to S3:", error);
      return false;
    }
  };

  const uploadFileMetadata = async (fileData) => {
    try {
      const token = getAccessToken();
      if (!token) {
        console.error("No authentication token available.");
        return false;
      }
      const response = await axios.post("/api/v1/storage/files", fileData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        return true;
      } else {
        console.error("Error uploading file metadata:", response.statusText);
        return false;
      }
    } catch (error) {
      console.error("Error uploading file metadata:", error);
      return false;
    }
  };

  const uploadFile = (files, projectId, companyId, subcategory, category) => {
    if (!files || files.length === 0) return;
    return handleFileUpload(files, category, projectId, companyId, subcategory);
  };

  const handleFileUpload = async (
    files,
    category,
    projectId,
    companyId,
    subcategory
  ) => {
    setUploading(true); // Set uploading to true when the upload starts
    // File size check
    for (let file of files) {
      const fileSizeInMB = file.size / (1024 * 1024); // size in MB
      const maxSizeInMB = 50; // Maximum size allowed in MB

      if (fileSizeInMB > maxSizeInMB) {
        const proceed = window.confirm(
          `Bitte beachten Sie: Sie sind dabei, eine große Datei hochzuladen. Dieser Vorgang kann etwas länger als gewöhnlich dauern.`
        );
        if (!proceed) {
          setUploading(false); // If user decides not to proceed, set uploading to false and exit
          return;
        }
      }
      // Step 1: Get the pre-signed URL
      const filePath = `${companyId}/${projectId}/${file.name}`;

      const presignedData = await getPresignedUrl(filePath);

      // Step 2: Upload the file to S3
      const uploadToS3Success = await uploadFileToS3(presignedData.url, file);
      if (!uploadToS3Success) {
        alert("Failed to upload file to S3");
        setUploading(false); // If upload fails, set uploading to false
        return;
      }

      // Step 3: Upload file data to the database
      const fileData = {
        filename: file.name,
        url: presignedData.url,
        path: filePath,
        category: category,
        project_id: projectId,
        client_id: companyId,
        extension: file.type,
        subcategory: subcategory,
        status: "new",
      };
      const uploadMetadataSuccess = await uploadFileMetadata(fileData);

      if (!uploadMetadataSuccess) {
        toast.error("Failed to upload file metadata");
        setUploading(false); // If metadata upload fails, set uploading to false
        return;
      }

      // Success
      // alert("File uploaded successfully");
      toast.success(`Datei ${file.name} erfolgreich hochgeladen`);
      listObjects();
    }
    setUploading(false); // After all the upload processes, set uploading to false
  };

  const downloadFile = async (filePath, fileName) => {
    try {
      const presignedUrlData = await getPresignedUrl(filePath, "get_object");
      // Download the file using the presigned URL
      const response = await axios.get(presignedUrlData.url, {
        responseType: "blob",
      });
      // Create a link to download the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      // Remove the link after download
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };
  const updateFileStatus = async (fileId, newStatus) => {
    const token = getAccessToken();
    try {
      const response = await axios.put(
        `/api/v1/storage/files/${fileId}?status=${encodeURIComponent(newStatus)}
        `,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.status === 200) {
        toast.success("Dateistatus erfolgreich aktualisiert");
        listObjects();
      } else {
        console.error("Error updating file status:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating file status:", error);
    }
  };

  const getPreviewUrl = async (filePath) => {
    try {
      const presignedUrlData = await getPresignedUrl(filePath, "get_object");
      return presignedUrlData.url;
    } catch (error) {
      console.error("Error getting the preview URL:", error);
    }
  };
  // TODO: After deleting the file record from the database, ensure the file is also deleted from the S3 bucket.

  const deleteFileFromS3 = async (presignedUrl) => {
    try {
      await axios.delete(presignedUrl);
      return true;
    } catch (error) {
      console.error("Error deleting file from S3:", error);
      return false;
    }
  };
  const deleteFile = async (filePath, fileId) => {
    const confirmDelete = window.confirm(
      "Sind Sie sicher, dass Sie diese Datei löschen möchten?"
    );
    if (confirmDelete) {
      const presignedData = await getPresignedUrl(filePath, "delete_object");
      const deleteFromS3Success = await deleteFileFromS3(presignedData.url);
      if (!deleteFromS3Success) {
        alert("Failed to delete file from S3");
        return;
      }
      try {
        const token = getAccessToken();
        const response = await axios.delete(`/api/v1/storage/files/${fileId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 204) {
          toast.success("Datei erfolgreich gelöscht");
          listObjects();
        } else {
          console.error("Error deleting file:", response.statusText);
        }
      } catch (error) {
        console.error("Error deleting file:", error);
      }
    } else {
      toast.error("Die Dateilöschung wurde abgebrochen");
    }
  };
  return {
    files,
    listObjects,
    updateFileComment,
    getPresignedUrl,
    uploadFileToS3,
    uploadFileMetadata,
    uploadFile,
    handleFileUpload,
    downloadFile,
    updateFileStatus,
    getPreviewUrl,
    uploading,
    deleteFile,
    uploadProgress,
  };
};
